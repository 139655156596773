// src/components/TheatrePage.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, getDocs, writeBatch, query, where } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the path if needed
import './TheatrePage.css';
import useDataStore from '../DataStore';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';

const TheatrePage = () => {
  const { id } = useParams();
  const [shows, setShows] = useState([]);
  const {uid, globalShowType, globalShowId, setGlobalShowType, setGlobalShowId, setSelectedSeats, setTotalPrice, selectedSeats} = useDataStore();

  //To release the selected seats
  useEffect(() => {
    const updateSeatsAndPrice = async (uid) => {
      // Create a batch to handle multiple updates
      const batch = writeBatch(db);
      
      // Query for seats with lock equal to uid
      const seatsQuery = query(
        collection(db, 'theatres', id, globalShowType, globalShowId, 'bookings'),
        where('lock', '==', uid)
      );
      
      // Get the query snapshot
      const querySnapshot = await getDocs(seatsQuery);
      
      // Update all seats that match the lock condition
      querySnapshot.forEach((doc) => {
        const seatDocRef = doc.ref; // Reference to the seat document
        batch.update(seatDocRef, { status: 'available', at: null, lock: null });
      });
      
      // Commit the batch update
      await batch.commit();
      
      // Clear selected seats and reset total price
      setSelectedSeats([]);
      setTotalPrice(0);
    };
    if(globalShowType !== 'blank' && globalShowId !== 'blank') {
      updateSeatsAndPrice(uid);
      setGlobalShowType('blank');
      setGlobalShowId('blank');
    };
  }, [globalShowId, globalShowType, id, selectedSeats, setGlobalShowType, setGlobalShowId, setSelectedSeats, setTotalPrice, uid]);

  useEffect(() => {
    const fetchShows = async () => {
      try {
        //console.log('Theatre ID:', id); // Debugging: Check the value of id
        const showCollections = [];
        const showTypes = ['first', 'second', 'third'];
        const showPromises = showTypes.map(async (showType) => {
          const theatreCollection = collection(db, 'theatres', id, showType);
          const theatreSnapshot = await getDocs(theatreCollection);

          if (!theatreSnapshot.empty) {
            theatreSnapshot.forEach((doc) => {
              showCollections.push({ id: doc.id, ...doc.data(), showType });
            });
          } else {
            console.log('No such document for show type:', showType);
          }
        });

        await Promise.all(showPromises);
        setShows(showCollections);
        //console.log(showCollections);
      } catch (error) {
        console.error('Error fetching shows:', error);
      }
    };

    fetchShows();
  }, [id]);

  if (shows.length === 0) {
    return <div>Loading...</div>;
  }
  

  return (
    <div className='container'>
    <Header/>
    <div className="theatre-page">
      <h1>{id} Theatre</h1>
      <h2>Available Shows</h2>
      <div className="shows-container">
        {shows.map(show => (
          <div key={show.id} className="show-card">
            <h3>{show.name}</h3>
            {show.image && <img src={show.image} alt={show.name} />}
            <p>{show.description}</p>
            <p>Location: {show.location}</p>
            <p>Date: {show.date}</p>
            <p>Time: {show.time}</p>
            {/** {show.showType === 'first' && (<Link to={`/${id}/${show.showType}/${show.id}`}>Book Tickets</Link>)} */}
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    <MobileMenu />
    </div>
  );
};

export default TheatrePage;
